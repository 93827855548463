import 'lib/assign-asset-host';

const bodyEl = document.body;
const entryPoint = bodyEl.dataset.jsEntryPoint;

(async () => {
  await import('environment.js' /* webpackChunkName: "environment" */);

  switch (entryPoint) {
    case 'admin':
      import('admin/index.js' /* webpackChunkName: "admin" */);
      break;
    case 'pages':
      import('pages/index.js' /* webpackChunkName: "pages" */);
      break;
    case 'iframe':
      import('yelp-iframes/index.jsx' /* webpackChunkName: "yelp-iframes" */);
      break;
    case 'amp':
      import('amp/index.js' /* webpackChunkName: "amp" */);
      break;
    case 'groupon':
      import('groupon.js' /* webpackChunkName: "groupon" */);
      break;
    default:
    //noop
  }
})();
